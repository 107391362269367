<template>
    <GrayBoxAppLayout />
</template>

<script>
import GrayBoxAppLayout from '@/components/shared/Loader/GrayBoxAppLayout.vue';

export default {
    name: 'AuthorizedRoute',
    components: {
        GrayBoxAppLayout,
    },
};
</script>
